@use '~/styles/modules' as *;

.MobileMenu {
  font-family: var(--font1);
  position: absolute;
  padding: 1.6rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-strong);
  color: var(--text-color);
  z-index: 11;
  font: $h1;

  @include medium-screens() {
    padding: 1.6rem 2.4rem;
  }

  .topContainer {
    width: 100%;
    height: 4rem;
    margin: 0 0 1.6rem 0;
    @include flexContainer();
  }

  .navMenu {
    margin-top: 4rem;
    height: 30rem;
    width: 100%;

    .navLinks {
      height: 100%;
      width: 100%;
      @include flexContainer(column);

      li {
        width: 100%;
        @include flexContainer();
        margin-bottom: 3.2rem;

        a > svg {
          margin-right: 1.6rem;
          transform: scale(1.5);
        }
      }
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: var(--background3);
  }

  .gradient {
    @include textGradient();
  }

  button {
    @include flexContainer();

    &.closeButton {
      padding: 0.8rem 0.4rem 0.8rem 0.4rem;
      @include flexContainer(row, center, center);
      border-radius: 50%;
      height: 40px;
      width: 40px;
      border: 1px solid rgba(255, 255, 255, 0.2);

      .icon {
        margin: 0 auto;
        padding: 0;
        max-width: 16px;
        max-height: 16px;
      }
    }

    .icon {
      margin: 0 auto;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

.MobileMenu.isHidden {
  opacity: 0;
  transition: opacity 0.3s ease-out;
  pointer-events: none;

  .navMenu {
    .navLinks li {
      opacity: 0;
      animation: slideDown 0.6s ease-in-out;
      animation-fill-mode: backwards;
    }
  }
}

.MobileMenu.isVisible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.2s ease-in-out;

  .navMenu {
    .navLinks li {
      animation-delay: 0.6s;
      animation: slideUp 0.75s ease-in-out;
      animation-fill-mode: backwards;
      color: var(--theme-grey6);
      font-weight: 600;

      .selected {
        position: relative;
        color: #ffffff;
        font-weight: 700;

        &::after {
          content: '';
          position: absolute;
          bottom: -0.4rem;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 2px;
          border-radius: 12px;
          background: linear-gradient(to right, #fa9579, #ffffff);
        }
      }
    }

    .navLinks li:nth-child(2) {
      animation-delay: 0.1s;
    }
    .navLinks li:nth-child(3) {
      animation-delay: 0.125s;
    }
    .navLinks li:nth-child(4) {
      animation-delay: 0.15s;
    }
    .navLinks li:nth-child(5) {
      animation-delay: 0.175s;
    }
    .navLinks li:nth-child(6) {
      animation-delay: 0.2s;
    }
  }

  button {
    animation-delay: 0.6s;
    animation: slideUp 0.7s ease-in-out;
    animation-fill-mode: both;

    &.closeButton {
      animation-delay: 0.6s;
      animation: rotate 0.3s ease-in-out;
      animation-fill-mode: both;
    }

    &:hover {
      background: rgba(32, 32, 32, 0.25);
      transition: background 0.2s ease-in-out;
    }
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
