@use '~/styles/modules' as *;

.FooterLink {
  color: var(--text-color);
  font: $p1;
  margin: 0;
  padding: 0;
}
.MFooterLink {
  color: var(--text-color);
  font: $p1;
  margin: 0 auto 1.6rem 0;
  @include small-screens() {
    margin: auto;
    margin: 1rem 0.8rem;
  }

  @include tablet-screens() {
    margin: auto;
    margin: 1rem 0.8rem;
  }

  a {
    padding: 0.8rem 0.8rem 0.8rem 0;
    @include tablet-screens() {
    }
  }
}
