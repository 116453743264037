@use '~/styles/modules' as *;

.Logo {
  flex: 0 0 auto;
  margin-right: auto;
  @include flexContainer(row, flex-start, center);
  z-index: 1;

  .logo {
    height: 2rem;
    @include small-screens() {
      height: 2.4rem;
    }
  }

  .betaTag {
    display: flex;
    align-items: center;
    margin-left: 8px;
    img {
      width: 36px;
      height: auto;
    }
  }

  .betaTagText {
    height: 2rem;
    padding: 0.4rem;
    margin: 0.1rem;
    border-radius: 3px;
    @include flexContainer(center, center);
    border: 1px solid $cream-gradient;
  }

  .grey {
    background: $grey2;
  }

  .black {
    background: $black;
  }
}

.noMargin {
  margin-right: 0 !important;
}
