@use '~/styles/modules' as *;

.footer {
  font-family: var(--font1);
  width: 100%;
  margin-top: auto;
  padding: 32px 0;
  z-index: 1;
  max-width: 1320px;

  main {
    @include flexContainer(column, center, center);
  }

  @include medium-screens {
    main {
      @include flexContainer(row, space-between, flex-start);
      gap: 16px;
    }
  }

  @include desktop-screens {
    main {
      @include flexContainer(row, center, flex-start);
      gap: 32px;
    }
  }

  .socials {
    @include flexContainer(row, space-between, center);

    & > div:first-child {
      @include flexContainer(row, space-between, center);
      gap: 16px;
      width: 100%;
    }

    ul {
      @include flexContainer(row, center, center);
      gap: 24px;

      li {
        cursor: pointer;
      }
    }

    @include desktop-screens {
      ul {
        gap: 40px;
      }
    }
  }

  @include medium-screens {
    .socials {
      & > div:first-child {
        justify-content: flex-start;
        gap: 72px;
      }
    }
  }

  .footerColumn {
    width: 100%;
    h5 {
      font-size: 2rem;
    }
    p {
      font-size: 1.4rem;
    }
  }

  .newsletter {
    @include flexContainer(column, center, flex-start);

    margin: 48px 0 0 0;
    & > div {
      width: 100%;
    }
    h5 {
      font-weight: 800;
      margin-bottom: 2.4rem;
    }
    p {
      font-weight: 500;
      margin-bottom: 1.6rem;

      @media screen and (min-width: 1440px) {
        margin-bottom: 24px;
      }
    }

    input {
      width: 100%;
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
      height: 40px;
    }

    @include small-screens {
      max-width: 640px;
      margin-right: auto;
      div {
        width: 100%;
        @include flexContainer(row, space-between, center);
        gap: 16px;
      }
      input {
        margin: 0;
        max-width: 500px;
      }
    }
    @include medium-screens {
      max-width: unset;
      margin-right: unset;
      div {
        @include flexContainer(column, center, flex-start);
        gap: 16px;
        @media screen and (orientation: portrait) {
          @include flexContainer(row, center, center);
        }
        @media screen and (orientation: landscape) {
          @include flexContainer(row, center, center);
        }
      }
      input {
        max-width: unset;
        margin: 0;
      }
    }
  }

  @include medium-screens {
    .newsletter {
      max-width: 460px;
    }
  }

  @include desktop-screens {
    .newsletter {
      max-width: 400px;

      div {
        gap: 16px;
      }
    }
  }

  .faqs {
    margin: 48px 0 0 0;
    div {
      @include flexContainer(column, flex-start, flex-start);
      width: auto;

      div:last-child {
        margin-top: 24px;
      }

      @include small-screens {
        div:last-child {
          margin-top: 0;
        }
      }
    }

    @include small-screens {
      & > div:first-child {
        @include flexContainer(row, flex-start, center);
        gap: 64px;
      }
    }

    @include medium-screens {
      & > div:first-child {
        @include flexContainer(row, space-evenly, flex-start);
        gap: 16px;
      }
    }

    h5 {
      margin-bottom: 24px;
    }

    li {
      font-weight: 600;
      margin: 0 0 16px 0;
      a {
        padding: 0;
      }
    }
  }
}

.listContainer {
  ul {
    @include flexContainer(row, flex-start, center);
    gap: 16px;
    margin: 48px 0 16px 0;
    li {
      a {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
    }
  }
  .projectBy {
    @include flexContainer(row, flex-start, center);
    font-size: 1.2rem;
    font-weight: 700;
  }
}

@include small-screens {
  .listContainer {
    margin: 48px 0 0 0;
    @include flexContainer(row, space-between, center);
    // order: -1;
    ul {
      order: 2;
      gap: 16px;
      width: auto;
      margin: 0;
      li {
        a {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
      }
    }
    .projectBy {
      order: 1;
      width: a;
    }
  }
}
