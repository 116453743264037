@use '~/styles/modules' as *;

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--overlay);
  z-index: 1060;
}
