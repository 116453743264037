@use '~/styles/modules' as *;

:root {
  font-size: 10px;
  position: relative;
  font-family: $font1;
  //  TRANSITIONS
  --transition-general: all 200ms ease-in-out;
}

// TODO: DEFINE THEMES HERE
body,
body[data-theme='dark'] {
  --background1: #{$grey1};
  --background2: #{$grey2};
  --background3: #{$grey3};
  --background4: #{$grey4};
  --background5: #{$grey5};
  --background6: #{$grey6};
  --background-strong: #{$black};
  --background-contrast: #{$white};
  --text-color: #{$white};
  --text-color-faded: #{$grey5};
  --overlay: #{$black-overlay};
  --gradient: #{$cream-gradient};
}

// ? DUMMY LIGHT THEME (none from client)
body[data-theme='light'] {
  --background1: #{$grey4};
  --background2: #{$grey3};
  --background3: #{$grey2};
  --background4: #{$grey1};
  --background5: #{$grey5};
  --background6: #{$grey6};
  --background-strong: #{$white};
  --background-contrast: #{$black};
  --text-color: #{$black};
  --text-color-faded: #{$grey5};
  --overlay: #{$white-overlay};
  --gradient: #{$cream-gradient};
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
  border: none;
  cursor: pointer;
  background: none;
  user-select: none;
  color: inherit;
  font: inherit;
}
button:focus {
  outline: none;
}
li {
  list-style: none;
  font: inherit;
}
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
a:focus {
  outline: none;
}
// .inner_link {
// width: 100%;
// height: 100%;
// display: flex;
// }

// MEDIA QUERIES

// @media screen and (min-width: 1301px) {
//   :root {
//     font-size: 10px;
//   }
// }

// @keyframes rotate {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(359deg);
//   }
// }
