@use '~/styles/modules' as *;

.AlertContainer {
  background: var(--background3);
}

.defaultBg {
  background-color: var(--background1);
}

.rewardsCodeBg {
  background: radial-gradient(
      100% 235.18% at 100% 0%,
      rgba(13, 151, 250, 0.3) 0%,
      rgba(16, 16, 16, 0.3) 100%
    ),
    linear-gradient(0deg, rgba(13, 151, 250, 0.1), rgba(13, 151, 250, 0.1)), #101010;
}

.rewardsDownloadBg {
  background: radial-gradient(
      100% 235.18% at 100% 0%,
      rgba(123, 97, 255, 0.3) 0%,
      rgba(16, 16, 16, 0.3) 100%
    ),
    linear-gradient(0deg, rgba(148, 126, 255, 0.1), rgba(148, 126, 255, 0.1)), #101010;
}

.rewardsLinkBg {
  background: radial-gradient(
      100% 235.18% at 100% 0%,
      rgba(251, 198, 59, 0.3) 0%,
      rgba(16, 16, 16, 0.3) 100%
    ),
    linear-gradient(0deg, rgba(251, 198, 59, 0.1), rgba(251, 198, 59, 0.1)), #101010;
}

.glowContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: blur(300px);
}

.glow {
  display: block;
  opacity: 0.3;
  width: 100%;
  height: 100vh;
  margin-top: 5vh;
  filter: blur(300px);
}
@include tablet-screens() {
  .glow {
    margin-top: 25vh;
    width: 50%;
    height: 30vh;
    opacity: 0.4;
  }
}

.creamGlow {
  margin-right: auto;
  opacity: 0.4;
  background: linear-gradient(-90deg, #fa9579 0%, #ffffff 100%);
}

.greenGlow {
  margin-left: auto;
  background: linear-gradient(90deg, #5fe062 0%, #ffffff 100%);
}

.yellowGlow {
  margin-right: auto;
  background: linear-gradient(180deg, #fff600 0%, #ffffff 100%);
}

.blueGlow {
  margin-left: auto;
  background: linear-gradient(90deg, #58def4 0%, #ffffff 100%);
}

@keyframes glowPulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.Layout {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: hidden;
  padding: 1.6rem;
  color: var(--text-color);
  @include scrollbar(0.8rem, var(--background4), transparent);

  @include flexContainer(column, flex-start, center);

  @include medium-screens() {
    padding: 1.6rem 2.4rem;
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    padding-inline: 4rem;
  }

  @include desktop-screens() {
    padding: 1.6rem 6.4rem;
  }

  .content {
    position: relative;
    padding-bottom: 4rem;
    width: 100%;
    max-width: 1312px;
    margin: 0 auto;
  }
}
